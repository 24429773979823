import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./assets/css/tailwind.output.css";
import App from "./App";
import { SidebarProvider } from "./context/SidebarContext";
import ThemedSuspense from "./components/ThemedSuspense";
import { Windmill } from "@windmill/react-ui";
import * as serviceWorker from "./serviceWorker";
import { AdminProvider } from "./context/AdminContext";
import "react-toastify/dist/ReactToastify.css";
import ToastProvider from "./context/ToastProvider";
import { GlobalStateProvider } from "./context/GlobalStateContext";

ReactDOM.render(
  <React.StrictMode>
    <AdminProvider>
      <ToastProvider>
        <GlobalStateProvider>
          <SidebarProvider>
            <Suspense fallback={<ThemedSuspense />}>
              <Windmill usePreferences>
                <App />
              </Windmill>
            </Suspense>
          </SidebarProvider>
        </GlobalStateProvider>
      </ToastProvider>
    </AdminProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.register();
